tinymce.addI18n('pl',{
"Redo": "Pon\u00f3w",
"Undo": "Cofnij",
"Cut": "Wytnij",
"Copy": "Kopiuj",
"Paste": "Wklej",
"Select all": "Zaznacz wszystko",
"New document": "Nowy dokument",
"Ok": "Ok",
"Cancel": "Anuluj",
"Visual aids": "Pomoce wizualne",
"Bold": "Pogrubienie",
"Italic": "Kursywa",
"Underline": "Podkre\u015blenie",
"Strikethrough": "Przekre\u015blenie",
"Superscript": "Indeks g\u00f3rny",
"Subscript": "Indeks dolny",
"Clear formatting": "Wyczy\u015b\u0107 formatowanie",
"Align left": "Wyr\u00f3wnaj do lewej",
"Align center": "Wyr\u00f3wnaj do \u015brodka",
"Align right": "Wyr\u00f3wnaj do prawej",
"Justify": "Do lewej i prawej",
"Bullet list": "Lista wypunktowana",
"Numbered list": "Lista numerowana",
"Decrease indent": "Zmniejsz wci\u0119cie",
"Increase indent": "Zwi\u0119ksz wci\u0119cie",
"Close": "Zamknij",
"Formats": "Formaty",
"Your browser doesn't support direct access to the clipboard. Please use the Ctrl+X\/C\/V keyboard shortcuts instead.": "Twoja przegl\u0105darka nie obs\u0142uguje bezpo\u015bredniego dost\u0119pu do schowka. U\u017cyj zamiast tego kombinacji klawiszy Ctrl+X\/C\/V.",
"Headers": "Nag\u0142\u00f3wki",
"Header 1": "Nag\u0142\u00f3wek 1",
"Header 2": "Nag\u0142\u00f3wek 2",
"Header 3": "Nag\u0142\u00f3wek 3",
"Header 4": "Nag\u0142\u00f3wek 4",
"Header 5": "Nag\u0142\u00f3wek 5",
"Header 6": "Nag\u0142\u00f3wek 6",
"Headings": "Nag\u0142\u00f3wki",
"Heading 1": "Nag\u0142\u00f3wek 1",
"Heading 2": "Nag\u0142\u00f3wek 2",
"Heading 3": "Nag\u0142\u00f3wek 3",
"Heading 4": "Nag\u0142\u00f3wek 4",
"Heading 5": "Nag\u0142\u00f3wek 5",
"Heading 6": "Nag\u0142\u00f3wek 6",
"Preformatted": "Sformatowany tekst",
"Div": "Div",
"Pre": "Sformatowany tekst",
"Code": "Kod \u017ar\u00f3d\u0142owy",
"Paragraph": "Akapit",
"Blockquote": "Blok cytatu",
"Inline": "W tek\u015bcie",
"Blocks": "Bloki",
"Paste is now in plain text mode. Contents will now be pasted as plain text until you toggle this option off.": "Wklejanie jest w trybie tekstowym. Zawarto\u015b\u0107 zostanie wklejona jako zwyk\u0142y tekst dop\u00f3ki nie wy\u0142\u0105czysz tej opcji.",
"Fonts": "Fonty",
"Font Sizes": "Rozmiar fontu",
"Class": "Klasa",
"Browse for an image": "Przegl\u0105daj za zdj\u0119ciem",
"OR": "LUB",
"Drop an image here": "Upu\u015b\u0107 obraz tutaj",
"Upload": "Prze\u015blij",
"Block": "Zablokuj",
"Align": "Wyr\u00f3wnaj",
"Default": "Domy\u015blne",
"Circle": "K\u00f3\u0142ko",
"Disc": "Dysk",
"Square": "Kwadrat",
"Lower Alpha": "Ma\u0142e litery",
"Lower Greek": "Ma\u0142e greckie",
"Lower Roman": "Ma\u0142e rzymskie",
"Upper Alpha": "Wielkie litery",
"Upper Roman": "Wielkie rzymskie",
"Anchor...": "Kotwica...",
"Name": "Nazwa",
"Id": "Identyfikator",
"Id should start with a letter, followed only by letters, numbers, dashes, dots, colons or underscores.": "Identyfikator powinien zaczyna\u0107 si\u0119 liter\u0105, dozwolone s\u0105 tylko litery, numery, uko\u015bniki, kropki, dwukropki i podkre\u015blniki - tzw. pod\u0142ogi",
"You have unsaved changes are you sure you want to navigate away?": "Masz niezapisane zmiany. Czy na pewno chcesz opu\u015bci\u0107 stron\u0119?",
"Restore last draft": "Przywr\u00f3\u0107 ostatni szkic",
"Special characters...": "Znaki specjalne...",
"Source code": "Kod \u017ar\u00f3d\u0142owy",
"Insert\/Edit code sample": "Dodaj\/Edytuj przyk\u0142adowy kod",
"Language": "J\u0119zyk",
"Code sample...": "Przyk\u0142ad kodu...",
"Color Picker": "Wybierz kolor",
"R": "R",
"G": "G",
"B": "B",
"Left to right": "Od lewej do prawej",
"Right to left": "Od prawej do lewej",
"Emoticons...": "Emotikony...",
"Metadata and Document Properties": "Metadane i w\u0142a\u015bciwo\u015bci dokumentu",
"Title": "Tytu\u0142",
"Keywords": "S\u0142owa kluczowe",
"Description": "Opis",
"Robots": "Roboty",
"Author": "Autor",
"Encoding": "Kodowanie",
"Fullscreen": "Pe\u0142ny ekran",
"Action": "Akcja",
"Shortcut": "Skr\u00f3t",
"Help": "Pomoc",
"Address": "Adres",
"Focus to menubar": "Skup si\u0119 na pasku menu",
"Focus to toolbar": "Skupi\u0107 si\u0119 na pasku",
"Focus to element path": "Skup si\u0119 na \u015bcie\u017cce elementu",
"Focus to contextual toolbar": "Skupi\u0107 si\u0119 na pasku narz\u0119dzi kontekstowych",
"Insert link (if link plugin activated)": "Wstaw \u0142\u0105cze (je\u015bli w\u0142\u0105czysz wtyczk\u0119 link\u00f3w)",
"Save (if save plugin activated)": "Zapisz (je\u015bli aktywowana jest wtyczka do zapisu)",
"Find (if searchreplace plugin activated)": "Znajd\u017a (je\u015bli w\u0142\u0105czysz wtyczk\u0119 do wyszukiwania)",
"Plugins installed ({0}):": "Zainstalowane wtyczki ({0}):",
"Premium plugins:": "Wtyczki Premium:",
"Learn more...": "Dowiedz si\u0119 wi\u0119cej...",
"You are using {0}": "U\u017cywasz {0}",
"Plugins": "Pluginy",
"Handy Shortcuts": "Przydatne skr\u00f3ty",
"Horizontal line": "Pozioma linia",
"Insert\/edit image": "Wstaw\/edytuj obrazek",
"Image description": "Opis obrazka",
"Source": "\u0179r\u00f3d\u0142o",
"Dimensions": "Wymiary",
"Constrain proportions": "Zachowaj proporcje",
"General": "Og\u00f3lne",
"Advanced": "Zaawansowane",
"Style": "Styl",
"Vertical space": "Odst\u0119p pionowy",
"Horizontal space": "Odst\u0119p poziomy",
"Border": "Ramka",
"Insert image": "Wstaw obrazek",
"Image...": "Obraz...",
"Image list": "Lista obrazk\u00f3w",
"Rotate counterclockwise": "Obr\u00f3\u0107 w lewo",
"Rotate clockwise": "Obr\u00f3\u0107 w prawo",
"Flip vertically": "Przerzu\u0107 w pionie",
"Flip horizontally": "Przerzu\u0107 w poziomie",
"Edit image": "Edytuj obrazek",
"Image options": "Opcje obrazu",
"Zoom in": "Powi\u0119ksz",
"Zoom out": "Pomniejsz",
"Crop": "Przytnij",
"Resize": "Zmiana rozmiaru",
"Orientation": "Orientacja",
"Brightness": "Jasno\u015b\u0107",
"Sharpen": "Wyostrz",
"Contrast": "Kontrast",
"Color levels": "Poziom koloru",
"Gamma": "Gamma",
"Invert": "Odwr\u00f3\u0107",
"Apply": "Zaakceptuj",
"Back": "Cofnij",
"Insert date\/time": "Wstaw dat\u0119\/czas",
"Date\/time": "Data\/Czas",
"Insert\/Edit Link": "Wstaw\/Dostosuj \u0142\u0105cze",
"Insert\/edit link": "Wstaw\/edytuj \u0142\u0105cze",
"Text to display": "Tekst do wy\u015bwietlenia",
"Url": "URL",
"Open link in...": "Otw\u00f3rz \u0142\u0105cze w...",
"Current window": "Aktualne okno",
"None": "\u017baden",
"New window": "Nowe okno",
"Remove link": "Usu\u0144 \u0142\u0105cze",
"Anchors": "Kotwice",
"Link...": "\u0141\u0105cze...",
"Paste or type a link": "Wklej lub wpisz adres \u0142\u0105cza",
"The URL you entered seems to be an email address. Do you want to add the required mailto: prefix?": "URL, kt\u00f3ry wprowadzi\u0142e\u015b wygl\u0105da na adres e-mail. Czy chcesz doda\u0107 mailto: jako prefiks?",
"The URL you entered seems to be an external link. Do you want to add the required http:\/\/ prefix?": "URL, kt\u00f3ry wprowadzi\u0142e\u015b wygl\u0105da na link zewn\u0119trzny. Czy chcesz doda\u0107 http:\/\/ jako prefiks?",
"Link list": "Lista link\u00f3w",
"Insert video": "Wstaw wideo",
"Insert\/edit video": "Wstaw\/edytuj wideo",
"Insert\/edit media": "Wstaw\/Edytuj media",
"Alternative source": "Alternatywne \u017ar\u00f3d\u0142o",
"Alternative source URL": "Alternatywny URL \u017ar\u00f3d\u0142a",
"Media poster (Image URL)": "Plakat (URL obrazu)",
"Paste your embed code below:": "Wklej tutaj kod do osadzenia:",
"Embed": "Osad\u017a",
"Media...": "Media...",
"Nonbreaking space": "Nie\u0142amliwa spacja",
"Page break": "Podzia\u0142 strony",
"Paste as text": "Wklej jako zwyk\u0142y tekst",
"Preview": "Podgl\u0105d",
"Print...": "Drukuj...",
"Save": "Zapisz",
"Find": "Znajd\u017a",
"Replace with": "Zamie\u0144 na",
"Replace": "Zamie\u0144",
"Replace all": "Zamie\u0144 wszystko",
"Previous": "Poprzedni",
"Next": "Nast.",
"Find and replace...": "Znajd\u017a i zamie\u0144...",
"Could not find the specified string.": "Nie znaleziono szukanego tekstu.",
"Match case": "Dopasuj wielko\u015b\u0107 liter",
"Find whole words only": "Znajd\u017a tylko ca\u0142e wyrazy",
"Spell check": "Sprawd\u017a pisowni\u0119",
"Ignore": "Ignoruj",
"Ignore all": "Ignoruj wszystko",
"Finish": "Zako\u0144cz",
"Add to Dictionary": "Dodaj do s\u0142ownika",
"Insert table": "Wstaw tabel\u0119",
"Table properties": "W\u0142a\u015bciwo\u015bci tabeli",
"Delete table": "Usu\u0144 tabel\u0119",
"Cell": "Kom\u00f3rka",
"Row": "Wiersz",
"Column": "Kolumna",
"Cell properties": "W\u0142a\u015bciwo\u015bci kom\u00f3rki",
"Merge cells": "\u0141\u0105cz kom\u00f3rki",
"Split cell": "Podziel kom\u00f3rk\u0119",
"Insert row before": "Wstaw wiersz przed",
"Insert row after": "Wstaw wiersz po",
"Delete row": "Usu\u0144 wiersz",
"Row properties": "W\u0142a\u015bciwo\u015bci wiersza",
"Cut row": "Wytnij wiersz",
"Copy row": "Kopiuj wiersz",
"Paste row before": "Wklej wiersz przed",
"Paste row after": "Wklej wiersz po",
"Insert column before": "Wstaw kolumn\u0119 przed",
"Insert column after": "Wstaw kolumn\u0119 po",
"Delete column": "Usu\u0144 kolumn\u0119",
"Cols": "Kol.",
"Rows": "Wiersz.",
"Width": "Szeroko\u015b\u0107",
"Height": "Wysoko\u015b\u0107",
"Cell spacing": "Odst\u0119py kom\u00f3rek",
"Cell padding": "Dope\u0142nienie kom\u00f3rki",
"Show caption": "Poka\u017c podpis",
"Left": "Lewo",
"Center": "\u015arodek",
"Right": "Prawo",
"Cell type": "Typ kom\u00f3rki",
"Scope": "Kontekst",
"Alignment": "Wyr\u00f3wnanie",
"H Align": "Wyr\u00f3wnanie w pionie",
"V Align": "Wyr\u00f3wnanie w poziomie",
"Top": "G\u00f3ra",
"Middle": "\u015arodek",
"Bottom": "D\u00f3\u0142",
"Header cell": "Kom\u00f3rka nag\u0142\u00f3wka",
"Row group": "Grupa wierszy",
"Column group": "Grupa kolumn",
"Row type": "Typ wiersza",
"Header": "Nag\u0142\u00f3wek",
"Body": "Tre\u015b\u0107",
"Footer": "Stopka",
"Border color": "Kolor ramki",
"Insert template...": "Wstaw szablon...",
"Templates": "Szablony",
"Template": "Szablon",
"Text color": "Kolor tekstu",
"Background color": "Kolor t\u0142a",
"Custom...": "Niestandardowy...",
"Custom color": "Kolor niestandardowy",
"No color": "Bez koloru",
"Remove color": "Usu\u0144 kolor",
"Table of Contents": "Spis tre\u015bci",
"Show blocks": "Poka\u017c bloki",
"Show invisible characters": "Poka\u017c niewidoczne znaki",
"Word count": "Liczba s\u0142\u00f3w",
"Words: {0}": "S\u0142\u00f3w: {0}",
"{0} words": "{0} s\u0142\u00f3w",
"File": "Plik",
"Edit": "Edycja",
"Insert": "Wstaw",
"View": "Widok",
"Format": "Format",
"Table": "Tabela",
"Tools": "Narz\u0119dzia",
"Powered by {0}": "Powered by {0}",
"Rich Text Area. Press ALT-F9 for menu. Press ALT-F10 for toolbar. Press ALT-0 for help": "Obszar Edycji. ALT-F9 - menu. ALT-F10 - pasek narz\u0119dzi. ALT-0 - pomoc",
"Image title": "Tytu\u0142 obrazu",
"Border width": "Grubo\u015b\u0107 ramki",
"Border style": "Styl ramki",
"Error": "B\u0142\u0105d",
"Warn": "Ostrze\u017cenie",
"Valid": "Poprawny",
"To open the popup, press Shift+Enter": "Aby otworzy\u0107 okienko, naci\u015bnij Shift+Enter",
"Rich Text Area. Press ALT-0 for help.": "Obszar tekstu sformatowanego. Naci\u015bnij ALT-0 aby uzyska\u0107 pomoc.",
"System Font": "Font systemowy",
"Failed to upload image: {0}": "Nie uda\u0142o si\u0119 przes\u0142a\u0107 obrazu: {0}",
"Failed to load plugin: {0} from url {1}": "Nie uda\u0142o si\u0119 za\u0142adowa\u0107 wtyczki: {0} z adresu {1}",
"Failed to load plugin url: {0}": "Nie uda\u0142o si\u0119 za\u0142adowa\u0107 adresu wtyczki: {0}",
"Failed to initialize plugin: {0}": "Nie mo\u017cna zainicjowa\u0107 wtyczki: {0}",
"example": "przyk\u0142ad",
"Search": "Znajd\u017a",
"All": "Wszystkie",
"Currency": "Waluty",
"Text": "Tekstowe",
"Quotations": "Cudzys\u0142owy",
"Mathematical": "Matematyczne",
"Extended Latin": "Dodatkowe",
"Symbols": "Symbole",
"Arrows": "Strza\u0142ki",
"User Defined": "W\u0142asny",
"dollar sign": "znak dolara",
"currency sign": "znak waluty",
"euro-currency sign": "znak euro",
"colon sign": "znak colon",
"cruzeiro sign": "znak cruzeiro",
"french franc sign": "znak franka francuskiego",
"lira sign": "znak liry",
"mill sign": "znak mill",
"naira sign": "znak nairy",
"peseta sign": "znak pesety",
"rupee sign": "znak rupii",
"won sign": "znak wona",
"new sheqel sign": "znak nowego szekla",
"dong sign": "znak donga",
"kip sign": "znak kipa",
"tugrik sign": "znak tugrika",
"drachma sign": "znak drachmy",
"german penny symbol": "znak feniga",
"peso sign": "znak peso",
"guarani sign": "znak guarani",
"austral sign": "znak australa",
"hryvnia sign": "znak hrywny",
"cedi sign": "znak cedi",
"livre tournois sign": "znak livre tournois",
"spesmilo sign": "znak spesmilo",
"tenge sign": "znak tenge",
"indian rupee sign": "znak rupii indyjskiej",
"turkish lira sign": "znak liry tureckiej",
"nordic mark sign": "znak nordic mark",
"manat sign": "znak manata",
"ruble sign": "znak rubla",
"yen character": "znak jena",
"yuan character": "znak juana",
"yuan character, in hong kong and taiwan": "znak juana w Hongkongu i Tajwanie",
"yen\/yuan character variant one": "jen\/juan wariant pierwszy",
"Loading emoticons...": "\u0141adowanie emotikon\u00f3w...",
"Could not load emoticons": "Nie mo\u017cna za\u0142adowa\u0107 emotikon\u00f3w",
"People": "Ludzie",
"Animals and Nature": "Zwierz\u0119ta i natura",
"Food and Drink": "Jedzenie i picie",
"Activity": "Aktywno\u015b\u0107",
"Travel and Places": "Podr\u00f3\u017ce i miejsca",
"Objects": "Obiekty",
"Flags": "Flagi",
"Characters": "Znak\u00f3w",
"Characters (no spaces)": "Znak\u00f3w (bez spacji)",
"Error: Form submit field collision.": "B\u0142\u0105d: Kolizja pola przesy\u0142ania formularza.",
"Error: No form element found.": "B\u0142\u0105d: nie znaleziono elementu formularza.",
"Update": "Aktualizuj",
"Color swatch": "Pr\u00f3bka koloru",
"Turquoise": "Turkusowy",
"Green": "Zielony",
"Blue": "Niebieski",
"Purple": "Purpurowy",
"Navy Blue": "Ciemnoniebieski",
"Dark Turquoise": "Ciemny Turkusowy",
"Dark Green": "Ciemny Zielony",
"Medium Blue": "\u015aredni Niebieski",
"Medium Purple": "\u015aredni Purpurowy",
"Midnight Blue": "Niebieska p\u00f3\u0142noc, Midnight Blue",
"Yellow": "\u017b\u00f3\u0142ty",
"Orange": "Pomara\u0144czowy",
"Red": "Czerwony",
"Light Gray": "Jasny Szary",
"Gray": "Szary",
"Dark Yellow": "Ciemny \u017b\u00f3\u0142ty",
"Dark Orange": "Ciemny Pomara\u0144czowy",
"Dark Red": "Ciemny Czerwony",
"Medium Gray": "\u015aredni Szary",
"Dark Gray": "Ciemny Szary",
"Black": "Czarny",
"White": "Bia\u0142y",
"Switch to or from fullscreen mode": "W\u0142\u0105cz lub wy\u0142\u0105cz tryb pe\u0142noekranowy",
"Open help dialog": "Otw\u00f3rz okienko pomocy",
"history": "historia",
"styles": "style",
"formatting": "formatowanie",
"alignment": "wyr\u00f3wnanie",
"indentation": "wci\u0119cie",
"permanent pen": "marker",
"comments": "komentarze",
"Anchor": "Kotwica",
"Special character": "Znak specjalny",
"Code sample": "Przyk\u0142ad kodu \u017ar\u00f3d\u0142owego",
"Color": "Kolor",
"Emoticons": "Ikony emocji",
"Document properties": "W\u0142a\u015bciwo\u015bci dokumentu",
"Image": "Obraz",
"Insert link": "Wstaw \u0142\u0105cze",
"Target": "Cel",
"Link": "Adres \u0142\u0105cza",
"Poster": "Plakat",
"Media": "Media",
"Print": "Drukuj",
"Prev": "Poprz.",
"Find and replace": "Znajd\u017a i zamie\u0144",
"Whole words": "Ca\u0142e s\u0142owa",
"Spellcheck": "Sprawdzanie pisowni",
"Caption": "Tytu\u0142",
"Insert template": "Wstaw szablon"
});