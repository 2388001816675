export default class Permissions
{
    public static Standard = class
    {
        public static AdminAccess = "Ideo.Core.StandardPermissions@AdminAccess";
        public static Superuser = "Ideo.Core.StandardPermissions@Superuser";
    };

    public static Core = class
    {
        public static Users = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Core.Controllers.UsersPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Core.Controllers.UsersPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Core.Controllers.UsersPermissions@Delete";
        };

        public static Roles = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Core.Controllers.RolesPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Core.Controllers.RolesPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Core.Controllers.RolesPermissions@Delete";
        };

        public static Teams = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Core.Controllers.TeamsPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Core.Controllers.TeamsPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Core.Controllers.TeamsPermissions@Delete";
        };

        public static Organization = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Core.Controllers.OrganizationPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Core.Controllers.OrganizationPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Core.Controllers.OrganizationPermissions@Delete";
        };

        public static CoreAdmin = class
        {
            public static Substitutions = "Ideo.Logito.Core.CorePermissions@Substitutions";
        };

        public static DataSources = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Core.Controllers.DataSourcesPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Core.Controllers.DataSourcesPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Core.Controllers.DataSourcesPermissions@Delete";
        };

        public static DynamicForms = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Core.Controllers.DynamicFormsPermissions@View";
            public static Categories = "Ideo.Core.App.Areas.Admin.Core.Controllers.DynamicFormsPermissions@Categories";
            public static Edit = "Ideo.Core.App.Areas.Admin.Core.Controllers.DynamicFormsPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Core.Controllers.DynamicFormsPermissions@Delete";
            public static Accept = "Ideo.Core.App.Areas.Admin.Core.Controllers.DynamicFormsPermissions@Accept";
            public static EditAll = "Ideo.Core.App.Areas.Admin.Core.Controllers.DynamicFormsPermissions@EditAll";
            public static DeleteAll = "Ideo.Core.App.Areas.Admin.Core.Controllers.DynamicFormsPermissions@DeleteAll";
            public static AcceptAll = "Ideo.Core.App.Areas.Admin.Core.Controllers.DynamicFormsPermissions@AcceptAll";
        };

        public static Filters = class
        {
            public static Manage = "Ideo.Core.App.Areas.Admin.Core.Controllers.FiltersPermissions@Manage";
        };

        public static Admin = class
        {
            public static Security = "Ideo.Core.App.Areas.Admin.Core.Controllers.AdminPermissions@Security";
            public static Authlog = "Ideo.Core.App.Areas.Admin.Core.Controllers.AdminPermissions@Authlog";
            public static Changelog = "Ideo.Core.App.Areas.Admin.Core.Controllers.AdminPermissions@Changelog";
        };
    };

    public static Finance = class
    {
        public static Invoices = class
        {
            public static Add = "Ideo.Core.App.Areas.Finance.Invoices.InvoicePermissions@CanAdd";
            public static Coordinator = "Ideo.Core.App.Areas.Finance.Invoices.InvoicePermissions@Coordinator";
            public static Accountant = "Ideo.Core.App.Areas.Finance.Invoices.InvoicePermissions@Accountant";
        };
    };

    public static Cms = class
    {
        public static Pages = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Cms.Controllers.PagesPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Cms.Controllers.PagesPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Cms.Controllers.PagesPermissions@Delete";
        };

        public static Layouts = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Cms.Controllers.LayoutsPermissions@View";
            public static Compose = "Ideo.Core.App.Areas.Admin.Cms.Controllers.LayoutsPermissions@Compose";
            public static Publish = "Ideo.Core.App.Areas.Admin.Cms.Controllers.LayoutsPermissions@Publish";
            public static Edit = "Ideo.Core.App.Areas.Admin.Cms.Controllers.LayoutsPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Cms.Controllers.LayoutsPermissions@Delete";
        };

        public static Websites = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Cms.Controllers.WebsitesPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Cms.Controllers.WebsitesPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Cms.Controllers.WebsitesPermissions@Delete";
        };

        public static Articles = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ArticlesPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ArticlesPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ArticlesPermissions@Delete";
            public static Accept = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ArticlesPermissions@Accept";
            public static Publish = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ArticlesPermissions@Publish";
            public static EditAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ArticlesPermissions@EditAll";
            public static DeleteAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ArticlesPermissions@DeleteAll";
            public static AcceptAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ArticlesPermissions@AcceptAll";
            public static PublishAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ArticlesPermissions@PublishAll";
        };

        public static Informations = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Cms.Controllers.InformationsPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Cms.Controllers.InformationsPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Cms.Controllers.InformationsPermissions@Delete";
            public static Accept = "Ideo.Core.App.Areas.Admin.Cms.Controllers.InformationsPermissions@Accept";
            public static Publish = "Ideo.Core.App.Areas.Admin.Cms.Controllers.InformationsPermissions@Publish";
            public static EditAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.InformationsPermissions@EditAll";
            public static DeleteAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.InformationsPermissions@DeleteAll";
            public static AcceptAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.InformationsPermissions@AcceptAll";
            public static PublishAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.InformationsPermissions@PublishAll";
        };

        public static Faq = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Cms.Controllers.FaqPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Cms.Controllers.FaqPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Cms.Controllers.FaqPermissions@Delete";
            public static Accept = "Ideo.Core.App.Areas.Admin.Cms.Controllers.FaqPermissions@Accept";
            public static Publish = "Ideo.Core.App.Areas.Admin.Cms.Controllers.FaqPermissions@Publish";
            public static EditAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.FaqPermissions@EditAll";
            public static DeleteAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.FaqPermissions@DeleteAll";
            public static AcceptAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.FaqPermissions@AcceptAll";
            public static PublishAll = "Ideo.Core.App.Areas.Admin.Cms.Controllers.FaqPermissions@PublishAll";
        };

        public static Tags = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Cms.Controllers.TagsPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Cms.Controllers.TagsPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Cms.Controllers.TagsPermissions@Delete";
            public static Publish = "Ideo.Core.App.Areas.Admin.Cms.Controllers.TagsPermissions@Publish";
        };

        public static ContactForms = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ContactFormsPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ContactFormsPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Cms.Controllers.ContactFormsPermissions@Delete";
        };
    };

    public static Saas = class
    {
        public static Databases = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Saas.Controllers.DatabasesPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Saas.Controllers.DatabasesPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Saas.Controllers.DatabasesPermissions@Delete";
        };

        public static Storages = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Saas.Controllers.StoragesPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Saas.Controllers.StoragesPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Saas.Controllers.StoragesPermissions@Delete";
        };

        public static Tenants = class
        {
            public static View = "Ideo.Core.App.Areas.Admin.Saas.Controllers.TenantsPermissions@View";
            public static Edit = "Ideo.Core.App.Areas.Admin.Saas.Controllers.TenantsPermissions@Edit";
            public static Delete = "Ideo.Core.App.Areas.Admin.Saas.Controllers.TenantsPermissions@Delete";
        };
    };

    public static Storage = class
    {
        public static View = "Ideo.Core.App.Areas.Admin.Core.Controllers.Storage.StoragePermissions@View";
    };

    public static Trash = class
    {
        public static View = "Ideo.Core.App.Areas.Admin.Core.Controllers.TrashPermissions@View";
        public static Recover = "Ideo.Core.App.Areas.Admin.Core.Controllers.TrashPermissions@Recover";
        public static Delete = "Ideo.Core.App.Areas.Admin.Core.Controllers.TrashPermissions@Delete";
    };

    public static HrEFiles = class
    {
        public static View = "Ideo.Core.App.Areas.Hr.Files.HrFilesPermissions@View";
        public static AddFile = "Ideo.Core.App.Areas.Hr.Files.HrFilesPermissions@AddFile";
    };
}
